/** *
 * Groups
 * Page component for Groups tab.
 */

import React, {useState, useEffect} from 'react';

import GroupsPageTable from './subcomponents/GroupsPageTable';

import {
  API_GROUP_ADD_NEW,
  API_GROUPS_DELETE,
  API_GROUPS_ALLGROUPS
} from '../js/Configuration';

import {
  apirequest,
  getAuthData,
  isAdmin
} from '../js/Utilities';

import {
  deleteGroupConfirmation,
  setStateAfterGroupDelete
} from '../actions/GroupsUtils';

import '../css/Groups.css';

const GroupsPage = (props) => {
  const [groupList, setGroupList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [groupItem, setGroupItem] = useState(0);
  const [addToGroupId, setAddToGroupId] = useState(0);
  const [showChannelInfo, setShowChannelInfo] = useState(false)
  const [showChannels, setShowChannels] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [groupNameError, setGroupNameError] = useState(null)

  const getAllGroups = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_ALLGROUPS, {body: fd}, (data) => {
      setGroupList(data);
    });
  }
  
  const showGroupEdit = () => {
    console.log('IDK WHAT THIS DOES')
  }

  useEffect(() => {
    if(!groupList.length) {
      getAllGroups();
    }
  }, [groupList]);

  const onInputHandler = (domEvent) => {
   setGroupName(domEvent.target.value);
  }

  const makeGroupEdit = () => {
    if(showEdit) {
      return (
        <GroupsPageEditGroups
          showChannelInfo={showChannelInfo}
          showChannels={showChannels}
          currentChannel={currentlyViewedChannel}
          groups={groupList}

          channels={channelList}
          currentChannelPage={+channelListCurrentPage}
          channelListPerPage={+channelListPerPage}
          channelListAscending={channelListAscending}
          channelListFilterKeyword={channelListFilterKeyword}
          channelListFilterOn={channelListFilterOn}
          channelListSortBy={channelListSortBy}
          onPerPageChange={changePerPageHandler}
          onChannelSelectChange={channelSelectChangeHandler}
          totalChannelPages={channelListPageCount}
          groupItem={groupItem}
         />
      );
    } else {
        return null;
    }
  }

  const createGroup = (domEvent) => {
    domEvent.preventDefault();

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUP_ADD_NEW,{body: fd}, (data) => {
      if(data.result === 'success') {
        setGroupList([]);
        setGroupName('');
      } else {
        setGroupNameError(data.message);
      }
    });
  }

  const deleteGroup = (domEvent) => {
    const group = groupList.filter((g) => {
      return g.group_id === domEvent.target.dataset.groupid;
    });
    
    const confirm = window.confirm(`Confirm that you want to delete "${group[0].group_name}" by clicking OK.`);

    if(!confirm) return;
    
    const fd = new FormData();
    fd.set('id', group[0].group_id);
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_DELETE, {body: fd}, (response) => {
      if(response.success) {
        setGroupList(setStateAfterGroupDelete(groupList, response ));
        deleteGroupConfirmation(group[0].group_name);
      } else {
        window.alert(`Could not delete ${group[0].group_name}.`);
      }
    });
  }

  return (
    <div id="curationContent">
      <div id="curationMenu">
        <div className="groupHeaderParent">
            <form action={API_GROUP_ADD_NEW} method="post" onSubmit={createGroup} className="groupHeader form__single__input">
              <div className="form__input_button_group">
                <label htmlFor="group_name">Create Group:</label>
                <span>
                  <input name="groupname" id="group_name" value={groupName} onInput={onInputHandler} required={true} type="text" />
                  <button type="submit" className="btn btn-sm btn--action">Submit</button>
                </span>
              </div>
              <p className="msg__error" hidden={!groupNameError}>{groupNameError}</p>
            </form>
        </div>
        <GroupsPageTable groupList={groupList} onDelete={deleteGroup} onRowSelect={showGroupEdit} isAdmin={isAdmin(props.project)} />
        </div>
        { makeGroupEdit() }
    </div>
  )
}

export default GroupsPage;