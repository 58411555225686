import { daysInYear } from "./constants.mjs";

/**
 * @name yearsToDays
 * @category Conversion Helpers
 * @summary Convert years to days.
 *
 * @description
 * Convert a number of years to a full number of days.
 *
 * @param years - The number of years to be converted
 *
 * @returns The number of years converted in days
 *
 * @example
 * // Convert 2 years into days
 * const result = yearsToDays(2)
 * //=> 730
 */
export function yearsToDays(years) {
  return Math.trunc(years * daysInYear);
}

// Fallback for modularized imports:
export default yearsToDays;
