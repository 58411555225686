import { monthsInQuarter } from "./constants.mjs";

/**
 * @name monthsToQuarters
 * @category Conversion Helpers
 * @summary Convert number of months to quarters.
 *
 * @description
 * Convert a number of months to a full number of quarters.
 *
 * @param months - The number of months to be converted.
 *
 * @returns The number of months converted in quarters
 *
 * @example
 * // Convert 6 months to quarters:
 * const result = monthsToQuarters(6)
 * //=> 2
 *
 * @example
 * // It uses floor rounding:
 * const result = monthsToQuarters(7)
 * //=> 2
 */
export function monthsToQuarters(months) {
  const quarters = months / monthsInQuarter;
  return Math.trunc(quarters);
}

// Fallback for modularized imports:
export default monthsToQuarters;
